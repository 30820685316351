import React, { useState, useEffect } from "react";
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Slider = (props) => {
    const { slides, slidetime = 5 } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
        let slideInterval;
        if (slides.length > 0) {
            slideInterval = setInterval(() => {
                if (currentSlide === slides.length - 1) {
                    setCurrentSlide(0);
                } else {
                    setCurrentSlide(currentSlide + 1);
                }
            }, 1000 * slidetime);
        }
        return () => clearInterval(slideInterval);
    });
    const goNext = () => {
        if (currentSlide === slides.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };
    const goPrev = () => {
        if (currentSlide === 0) {
            setCurrentSlide(slides.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };
    return <S>
        {(slides.length > 0) && <div>
            {slides.map((slide, idx) => <div className={`slide ${idx === currentSlide ? 'visible' : 'hidden'}`} style={{ background: `url(${slide.bild}) center top`, backgroundSize: 'cover' }}></div>)}
            <div className="slideNext" onClick={() => { goNext() }}>
                <Icon
                    name="chevron right"
                />
            </div>
            <div className="slidePrev" onClick={() => { goPrev() }}>
                <Icon
                    name="chevron left"
                />
            </div>
        </div>}
    </S>
}

export default Slider;

const S = styled.div`
position: relative;
height:65vh;
.slidePrev{
    position:absolute;
    z-index:1;
    left:calc(2rem + 96px);
    top:30vh;
    cursor:pointer;
    font-size:2rem;
}
.slideNext{
    position:absolute;
    z-index:1;
    right:2rem;
    top:30vh;
    cursor:pointer;
    font-size:2rem;
}
.slide{
    height:65vh;
    width:100vw;
    position:absolute;
    top:0;
    z-index:0;
    &.visible{
      max-height:auto;
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: ease-in-out;
      opacity: 100;
    }
    &.hidden{
        transition-property: all;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
        opacity: 0;
    }
}
`;