import { atom, selectorFamily } from "recoil";
import json from "superagent/lib/node/parsers/json";
import { getPage, getMenu, getJobs, getJob, getCacheUrl } from './index';
export const globalState = atom({
    key: "globalState", // unique ID (with respect to other atoms/selectors)
    default: {} // default value (aka initial value)
});
export const scrollState = atom({
    key: "scrollState", // unique ID (with respect to other atoms/selectors)
    default: 0 // default value (aka initial value)
});
export const pagesState = atom({
    key: "pagesState", // unique ID (with respect to other atoms/selectors)
    default: {} // default value (aka initial value)
})
export const pageQuery = selectorFamily({
    key: 'pageQuery',
    get: slug => async () => {
        const response = await getPage(slug);
        if (response && response.error) {
            throw response.error;
        }
        return response;
    },
});
export const menuQuery = selectorFamily({
    key: 'menuQuery',
    get: slug => async () => {
        const response = await getMenu(slug);
        if (response.error) {
            throw response.error;
        }
        return response;
    },
});
export const jobQuery = selectorFamily({
    key: 'jobQuery',
    get: slug => async () => {
        const response = await getJob(slug);
        if (response && response.error) {
            throw response.error;
        }
        return response;
    },
});
export const jobsQuery = selectorFamily({
    key: 'jobsQuery',
    get: () => async () => {
        const response = await getJobs();
        if (response && response.error) {
            throw response.error;
        }
        return response;
    },
});