import React, { useState, useEffect } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, Image, Divider } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { jobQuery } from '../../App/atom';
import styled from 'styled-components';
import { get } from 'lodash';
import PageTitle from '../../Components/PageTitle';
import Slider from '../../Components/Slider';
import BackgroundText from '../../Components/BackgroundText';
import dummyImage from '../../transparent.png';

const Page = () => {
  const params = useParams();
  const { slug } = params;
  const [imgUrl, setImgUrl] = useState(false);
  const [slides, setSlides] = useState([]);
  const { contents: job } = useRecoilValueLoadable(jobQuery(slug));
  const navigate = useNavigate();
  useEffect(() => {
    if (job) {
      setImgUrl(get(job, 'acf.bild_header', get(job, 'acf.header_image')));
      setSlides(get(job, 'acf.header_slider', false));

    }
  }, [job]);
  const jobPosting = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: get(job, 'acf.jobtitel', ''),
    description: get(job, 'acf.beschreibung', ''),
    datePosted: get(job, 'modified', ''),
    employmentType: get(job, 'acf.art_der_beschaeftigung', ''),
    hiringOrganization: {
      '@type': 'Organization',
      name: get(job, 'acf.firmenname', ''),
      sameAs: get(job, 'acf.firmenwebseitenadresse', ''),
      logo: get(job, 'acf.url_firmenlogo', ''),
    },
    jobLocation: get(job, 'acf.arbeitsplatzorte', []).map((a) => ({
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: get(a, 'strasse', ''),
        addressLocality: get(a, 'ort', ''),
        postalCode: get(a, 'postleitzahl', ''),
        addressCountry: get(a, 'land', ''),
      },
    })),
  };
  if (get(job, 'acf.bewerbungsfrist', '')) {
    jobPosting.validThrough = get(job, 'acf.bewerbungsfrist', '');
  }
  return <S>
    <script type="application/ld+json">{JSON.stringify(jobPosting)}</script>
    <Helmet
      title={get(job, 'acf.seo_title', get(job, 'title.rendered'))}
      meta={[
        { name: 'description', content: get(job, 'acf.seo_description', '') },
      ]}
    />
    {(imgUrl && (slides.length == 0 || !slides)) && <div className="headerimg" style={{ background: `url(${imgUrl}) center top`, backgroundSize: 'cover' }}></div>}
    {(slides && slides.length > 0) && <Slider slides={slides} />}
    <Container>
      <PageTitle title1={get(job, 'title.rendered', '')} title2={get(job, 'acf.titel_2', '')} />
      <Grid stackable className="contentBlocks" divided relaxed>
        <BackgroundText
          text={get(job, 'acf.hintergrundtext', '')}
        />
        <Grid.Row columns="2">
          <Grid.Column
            className="contentLeft" width="2" only="computer"
          />
          <Grid.Column className="contentLeft" computer="14" mobile="16">
            <div className="imageBlock">
              <Image
                src={get(job, 'acf.bild', get(job, 'acf.header_image', dummyImage))} fluid
              />
            </div>
            <div className="contentBlock">
              <div
                dangerouslySetInnerHTML={{ __html: get(job, 'acf.beschreibung', '') }}
              />
              <Divider />
              <a
                className="downloadlink"
                href={get(job, 'acf.pdf', '')}
                target="_blank"
              >
                Download Stellenausschreibung
              </a>
              <div className="jobdetails">
                <h3>Stellenbezeichnung:</h3>
                {get(job, 'acf.jobtitel')}
                <h3>Arbeitsorte:</h3>
                {get(job, 'acf.arbeitsplatzorte', []).map((a) =>
                  <div>
                    {get(a, 'land', '')} {get(a, 'postleitzahl', '')} {get(a, 'ort', '')}
                  </div>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

  </S>
}

export default Page;

const S = styled.div`
.downloadlink{
    margin:1rem 0 1rem 0;
    padding:1rem 0;
    color:${props => props.theme.BLUE}!important;
  }
  .jobdetails{
    h3{
      margin:1rem 0 0 0;
      font-weight:normal;
    }
  }
.MapToggle{
  background:rgba(255,255,255,0.9);
  padding:0.5rem;
  max-width:calc(100% - 2rem);
  position:absolute;
  z-index:99;
  left:1rem;
  top:1rem;
  cursor:pointer;
}
.MapWrapper{
  position:relative;
}
.contentBlocks {
    @media screen and (max-width: 767px) {
      margin-top: 0 !important;
    }
    position:relative;
    a {
      text-decoration: none;
    }
    h2 {
      color:  ${props => props.theme.GREY} !important;
      font-size: 1.3rem;
      margin-bottom: 2.5rem !important;
      @media screen and (max-width: 767px) {
        margin-bottom: 0.5rem !important;
      }
    }
    h3 {
      color:  ${props => props.theme.GREY};
      font-weight: normal;
      text-decoration: none;
      font-size: 1.1rem;
    }
    ul{
      padding-left:20px;
    }
  }
  .googleMaps{

  }
  .imageBlock{
    width:30%;
    z-index:3;
    float:left;
    margin-top:-3rem;
    @media screen and (max-width: 767px) {
      position:absolute;
      width:2.8rem;
      display:inline-block;
      margin-top:-2.5rem;
      margin-left:-0.5rem;
      img{
        float:left !important;
      }
    }
  }
  .contentLeft{
    @media screen and (max-width: 767px) {
      border:none !important;
    }
    margin-top:2rem;
  }
  .contentBlock{
    @media screen and (max-width: 767px) {
      margin-top:-2rem !important;
      border:none !important;
    }
    background:rgba(112, 111, 111,0.1);
    padding:3rem 3rem 3rem 9rem;
    width:70%;
    z-index:2;
    float:left;
    margin-left:-6rem;
    @media screen and (max-width: 767px) {
      width:100%;
      margin-left:0;
      padding:3rem 1rem 1rem 1rem;
    }
  }
  .headerimg{
    height:65vh;
}
`;
