import React, { useState, useEffect } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, Image, Embed } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { pageQuery } from '../../App/atom';
import styled from 'styled-components';
import { get } from 'lodash';
import PageTitle from '../../Components/PageTitle';
import Slider from '../../Components/Slider';
import BackgroundText from '../../Components/BackgroundText';
import dummyImage from '../../transparent.png';
import map from '../../map.png';

const Page = (props) => {
  const params = useParams();
  const { slug } = params;
  const [imgUrl, setImgUrl] = useState(false);
  const [slides, setSlides] = useState([]);
  const { contents: pageData } = useRecoilValueLoadable(pageQuery(slug));
  const [maps, setMaps] = useState(false);
  const googleMaps = get(pageData, 'acf.googlemaps', false);
  const titleContent = get(pageData, 'acf.titel_content', false);
  const navigate = useNavigate();
  const navigateTo = (e, u) => {
    e.preventDefault();
    setActiveItem(u);
    navigate(u);
  };
  useEffect(() => {
    if (pageData) {
      setImgUrl(get(pageData, 'acf.header_image', get(pageData, 'acf.bild_header')));
      setSlides(get(pageData, 'acf.header_slider', false));

    }
  }, [pageData]);

  return <S>

    <Helmet
      title={get(pageData, 'acf.seo_title', get(pageData, 'title.rendered'))}
      meta={[
        { name: 'description', content: get(pageData, 'acf.seo_description', '') },
      ]}
    />
    {(imgUrl && (slides.length == 0 || !slides)) && <div className="headerimg" style={{ background: `url(${imgUrl}) center top`, backgroundSize: 'cover' }}></div>}
    {(slides.length > 0) && <Slider slides={slides} />}
    <Container>
      <PageTitle title1={get(pageData, 'acf.titel_1', '') || get(pageData, 'title.rendered', '')} title2={get(pageData, 'acf.titel_2', '')} />
      <Grid stackable className="contentBlocks" divided relaxed>
        <BackgroundText
          text={get(pageData, 'acf.hintergrundtext', '')}
        />
        <Grid.Row columns="3">
          <Grid.Column
            className="contentLeft" width="2" only="computer"
          />
          <Grid.Column className="contentLeft" computer="14" mobile="16">
            <div className="imageBlock">
              <Image
                src={get(pageData, 'acf.bild', get(pageData, 'acf.header_image', dummyImage))} fluid
              />
            </div>
            <div className="contentBlock">
              {titleContent ?
                <h2
                  dangerouslySetInnerHTML={{ __html: titleContent }}
                />
                : null}
              <div
                dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.content', '') }}
              />
              {googleMaps && maps ?
                <Embed
                  defaultActive url={googleMaps} className="googleMaps"
                />
                : null}
              {googleMaps && !maps ?
                <div className="MapWrapper">
                  <div className="MapToggle" onClick={() => setMaps(true)}>
                    Zum Aktivieren von GoogleMaps™ auf die Karte klicken, siehe unsere <a href="/de/datenschutz">Datenschutzbestimmungen</a>
                  </div>
                  <Image fluid src={map} onClick={() => setMaps(true)} />
                </div>
                : null}
            </div>
          </Grid.Column>
          <Grid.Column className="contentRight" tablet="6" computer="4">
            <h2
              dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.titel_rechter_inhaltsblock', '') }}
            />
            <Grid>
              {get(pageData, 'acf.quicklinks', []).map((elem, idx) => {
                const { link, icon, titel } = elem;
                const t = link.replace('https://cms.koenitzer.info', '');

                return (
                  <Grid.Column key={idx} mobile="8" tablet="8" computer="8" className="quicklink">
                    <a onClick={(e) => navigateTo(e, t)} href={t}>
                      <Image
                        src={icon} size="tiny" verticalAlign="middle" floated="left"
                      />
                      <h3>{titel}</h3>
                    </a>
                  </Grid.Column>
                );
              })}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

  </S>
}

export default Page;

const S = styled.div`
.MapToggle{
  background:rgba(255,255,255,0.9);
  padding:0.5rem;
  max-width:calc(100% - 2rem);
  position:absolute;
  z-index:99;
  left:1rem;
  top:1rem;
  cursor:pointer;
}
.MapWrapper{
  position:relative;
}
.contentBlocks {
    @media screen and (max-width: 767px) {
      margin-top: 0 !important;
    }
    position:relative;
    a {
      text-decoration: none;
    }
    h2 {
      color:  ${props => props.theme.GREY} !important;
      font-size: 1.3rem;
      margin-bottom: 2.5rem !important;
      @media screen and (max-width: 767px) {
        margin-bottom: 0.5rem !important;
      }
    }
    h3 {
      color:  ${props => props.theme.GREY};
      font-weight: normal;
      text-decoration: none;
      font-size: 1.1rem;
    }
    ul{
      padding-left:20px;
    }
  }
  .googleMaps{

  }
  .imageBlock{
    width:30%;
    z-index:3;
    float:left;
    margin-top:-3rem;
    @media screen and (max-width: 767px) {
      position:absolute;
      width:2.8rem;
      display:inline-block;
      margin-top:-2.5rem;
      margin-left:-0.5rem;
      img{
        float:left !important;
      }
    }
  }
  .contentLeft{
    @media screen and (max-width: 767px) {
      border:none !important;
    }
    margin-top:2rem;
  }
  .contentBlock{
    @media screen and (max-width: 767px) {
      margin-top:-2rem !important;
      border:none !important;
    }
    background:rgba(112, 111, 111,0.1);
    padding:3rem 3rem 3rem 9rem;
    width:70%;
    z-index:2;
    float:left;
    margin-left:-6rem;
    @media screen and (max-width: 767px) {
      width:100%;
      margin-left:0;
      padding:3rem 1rem 1rem 1rem;
    }
  }
  .headerimg{
    height:65vh;
}
`;
