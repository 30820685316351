import React, { } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Image } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { pageQuery } from '../../App/atom';
import styled from 'styled-components';
import { get } from 'lodash';
import PageTitle from '../../Components/PageTitle';
import BackgroundText from '../../Components/BackgroundText';

const Page = (props) => {
  const { contents: pageData } = useRecoilValueLoadable(pageQuery('home'));
  const imgUrl = get(pageData, 'acf.header_image', get(pageData, 'acf.bild_header'))
  const navigate = useNavigate();
  const navigateTo = (e, u) => {
    e.preventDefault();
    setActiveItem(u);
    navigate(u);
  };
  let links = get(pageData, 'acf.quicklinks', []);
  return <S>

    <Helmet
      title={get(pageData, 'acf.seo_title', get(pageData, 'title.rendered'))}
      meta={[
        { name: 'description', content: get(pageData, 'acf.seo_description', '') },
      ]}
    />
    {imgUrl && <div className="headerimg" style={{ background: `url(${imgUrl}) center top`, backgroundSize: 'cover' }}></div>}
    <Container>
      <PageTitle title1={get(pageData, 'acf.zwischentitel_1', '')} title2={get(pageData, 'acf.zwischentitel_2', '')} />
      <Grid stackable className="contentBlocks" divided relaxed>
        <BackgroundText
          text={get(pageData, 'acf.hintergrundtext', '')}
        />
        <Grid.Row columns="2">
          <Grid.Column
            className="contentLeft" width="2" only="computer"
          />
          <Grid.Column tablet="16" computer="14">
            <div className="contentBlocksN">
              <div className="contentLeft">
                <div className="contentBlock">
                  <h2
                    dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.titel_linker_inhaltsblock', '') }}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.inhalt_linker_inhaltsblock', '') }}
                  />
                </div>
              </div>
              <div className="contentRight">
                <h2
                  dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.titel_rechter_inhaltsblock', '') }}
                />
                {Array.isArray(links) && <div className="qlinks">
                  {get(pageData, 'acf.quicklinks', []).map((elem, idx) => {
                    const { link, icon, titel } = elem;
                    const t = link.replace('https://cms.koenitzer.info', '');

                    return (
                      <div key={idx} className="quicklink">
                        <a onClick={(e) => navigateTo(e, t)} href={t}>
                          <img
                            src={icon}
                          />
                          <h3>{titel}</h3>
                        </a>
                      </div>
                    );
                  })}
                </div>}
              </div>
            </div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

  </S>
}

export default Page;

const S = styled.div`
.qlinks{
  display:flex;
  flex-direction:column;
}
iframe{
  height:600px !important;
}
@media screen and (max-width: 767px) {
      .column{
        border:none !important;
      }
    }
.contentBlocksN{
  display:flex;
  justify-content:space-between;
  .contentRight{
    padding:2rem;
  }
  @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .contentLeft{
      position:relative;
      border-right: 1px dotted var(--GREY);
      padding-right:1rem;
      video, .wp-video{
        width:100%!important;
      }
    @media screen and (max-width: 767px) {
      padding-top:0px !important;
      margin-top:-2rem !important;
      border:none !important;
    }
  }
  .contentRight{
    flex: 1 0 190px;
    @media screen and (max-width: 767px) {
      border:none !important;
    }
  }
  .contentBlock{
    @media screen and (max-width: 767px) {
      margin-top:-2rem !important;
      h2{
        margin-bottom:0.5rem !important;
      }
    }
  }
}
.contentBlocks {
    position:relative;
    @media screen and (max-width: 767px) {
      margin-top: 0 !important;
    }
    a {
      text-decoration: none;
    }
    h2 {
      color:  ${props => props.theme.GREY} !important;
      font-size: 1.3rem;
      margin-bottom: 2.5rem !important;
    }
    h3 {
      color:  ${props => props.theme.GREY};
      font-weight: normal;
      text-decoration: none;
      font-size: 1.1rem;
    }
  }
  .contentLeft{
    @media screen and (max-width: 767px) {
      padding-top:0px !important;
      margin-top:-2rem !important;
      border:none !important;
    }
  }
  .contentRight{
    @media screen and (max-width: 767px) {
      border:none !important;
    }
  }
  .contentBlock{
    @media screen and (max-width: 767px) {
      margin-top:-2rem !important;
      h2{
        margin-bottom:0.5rem !important;
      }
    }
  }
  .quicklink{
    a{
      display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    text-align:center;
    > {
      flex:1 0 auto;
    }
    h3{
      margin:0.5rem 0;
    }
    img{
      margin:0.5rem 0;
      max-height:100px;
    }
  }
    @media screen and (max-width: 1023px) {
      img{
        float:none!important;
      }
    }
  }
.headerimg{
    height:65vh;
}
`;
