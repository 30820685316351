import React, { useState, useEffect } from 'react';
import { useRecoilValue } from "recoil";
import { Image, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { noop } from 'lodash';
import { scrollState } from '../../App/atom';

const HeaderLogo = (props) => {
  const [imgWidth, setImgWidth] = useState(68);
  const scrollPos = useRecoilValue(scrollState);
  const { logourl, mobilelogourl, onClick = noop, toggleMobileNav = noop } = props;
  const wrapperStyle = {
    padding: `${(imgWidth / 100) * 20}px`,
  };
  useEffect(() => {
    const x = scrollPos < 100 ? 150 - scrollPos : 68;
    if (x !== imgWidth) {
      setImgWidth(x);
    }
  }, [scrollPos]);
  return (
    <S style={wrapperStyle}>
      <Image
        src={logourl} width={imgWidth} onClick={onClick} className="desktopLogo"
      />
      <Image
        src={mobilelogourl} ui={false} onClick={onClick} className="mobileLogo"
      />
      <Button
        className="mobileNavButton" icon="bars" onClick={toggleMobileNav}
      />
    </S>
  );
}
const S = styled.div`
.mobileLogo{
    display:none;
  }
  @media screen and (max-width: 1023px) {
    max-width: 90px;
    padding:5px !important;
  }
  @media screen and (max-width: 767px) {
    padding:1rem !important;
    position: relative !important;
    img{
      width: calc(85vw - 2.5rem) !important;
    }
    width:100vw;
    max-width:100vw;
    .mobileLogo{
      display:block!important;
    }
    .desktopLogo{
      display:none !important;
    }
    .mobileNavButton{
      display:block !important;
      position:absolute;
      top:1rem;
      right:1rem;
      z-index:99;
      padding:0.2em !important;
      background:transparent !important;
      border:none !important;
      i{
        font-size:32px;
      }
    }
  }
  position: fixed !important;
  top: 0;
  left: 0;
  background: white;
  padding: 2rem;
  z-index: 1002 !important;
  max-width: 25vw;
  cursor: pointer;
  .mobileNavButton{
    display:none;
  }
  .desktopLogo{
    display:block ;
  }
`;
export default HeaderLogo;
