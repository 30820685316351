import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { menuQuery, scrollState } from '../../App/atom';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

const HeaderNav = () => {
  const { contents: menu } = useRecoilValueLoadable(menuQuery('menu_de'));
  const scrollPos = useRecoilValue(scrollState);
  const [activeItem, setActiveItem] = useState("/");
  const navigate = useNavigate();
  const navigateTo = (e, u) => {
    e.preventDefault();
    setActiveItem(u);
    navigate(u);
  };

  const maxSub = useMemo(() => scrollPos < 20 ? 20 : 20 + ((20 - scrollPos) * -0.07), [scrollPos]);
  const wrapperStyle = useMemo(() => {
    return {
      top: `${20 - maxSub}px`,
    }
  }, [maxSub]);
  return <S>
    <div className="HeaderNav" style={wrapperStyle}>
      <Menu pointing secondary className="HeaderNavMenu" floated="right">
        {(Array.isArray(menu) ? menu : []).map((item, idx) => {
          const { ID, title, url } = item;
          const t = url.replace('https://cms.koenitzer.info', '');
          const active = activeItem === t;
          return (
            <Menu.Item
              className={`menuItemLink ${active ? 'active' : ''}`} key={ID} link
              href={t} name={t} active={active} onClick={(e) => navigateTo(e, t)}
            >
              <div
                className={`menuItem ${menu.length - 1 === idx ? 'last' : ''}`}
              >
                <div className="menuItemText">
                  {title}
                </div>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>

  </S>;
}
export default HeaderNav;
const S = styled.div`
.HeaderNav{
  position: absolute;
  top: 2rem;
  right:2rem;
  width:75vw;
  z-index:901;
  @media screen and (max-width: 1023px) {
    width:85vw;
    right:1rem;
  }
  @media screen and (max-width: 767px) {
    display:none;
  }
}
.HeaderNavMenu{
  border-bottom:none!important;
  a{
    color:white !important;
    padding-left:1.9em !important;
    padding-right: 0 !important;
  }
  @media screen and (max-width: 1280px) {
    a{
      padding-left:1.3em !important;
    }
  }
  @media screen and (max-width: 1150px) {
    a{
      padding-left:0.7em !important;
    }
  }
  @media screen and (max-width: 1023px) {
    a{
      padding-left:0.5em !important;
      font-weight:normal!important;
    }
    .menuItem {
      font-size: 12px !important;
    }
  }
  .menuItem{
    border-right:2px solid white !important;
    padding-right:1.9em !important;
    font-size:16px !important;
    font-weight:normal;
    height:16px !important;
    &.last{
      border-right:none !important;
    }
    @media screen and (max-width: 1280px) {
      padding-right:1.3em !important;
    }
    @media screen and (max-width: 1150px) {
      padding-right:0.7em !important;
      border-right:1px solid white !important;
    }
    @media screen and (max-width: 1023px) {
      padding-right:0.3em !important;
      border-right:none !important;
    }
  }

  .menuItemLink{
    border:none !important;
    .menuItemText{
      padding-bottom:0.5rem;
    }
    &.active{
      .menuItem{
        .menuItemText{
          border-bottom:1px solid ${props => props.theme.BLUE} !important;
        }
      }
    }
    &:hover{
      .menuItem{
        .menuItemText{
          border-bottom:1px solid ${props => props.theme.BLUE} !important;
        }
      }
    }
  }
}
`;