import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const PageTitle = (props) => {
  const { title1, title2 } = props;
  return <S>
    <Grid className="PageTitle">
      <Grid.Row columns={title2 ? 2 : 1} only="computer">
        <Grid.Column className="headerTitle1">
          <h1 style={{ textAlign: title2 ? 'right' : 'center', }}>{title1}</h1>
        </Grid.Column>
        {title2 &&
          <Grid.Column className="headerTitle2">
            <h1>{title2}</h1>
          </Grid.Column>}
      </Grid.Row>
      <Grid.Row columns="1" only="tablet mobile">
        <Grid.Column className="headerTitleMobile">
          <h1>
            <span className="headerTitleMobile1">{title1}</span>
            {title2 ?
              <span className="headerTitleMobile2">&nbsp;{title2}</span>
              : null}
          </h1>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </S>;
};

export default PageTitle;

const S = styled.div`
display:block;
margin-bottom:2rem;
.headerTitle1 {
  padding-right: 0.5rem !important;
  text-align: right;
  color: ${props => props.theme.GREY};
  h1 {
    font-size:2.5em;
    font-weight: normal;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size:1.5em !important;
    }
  }
}

.headerTitle2 {
  padding-left: 0px !important;
  text-align: left;
  color:  ${props => props.theme.BLUE};
  h1 {
    font-size:2.5em;
    font-weight: bold;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size:1.5em !important;
    }
  }
}

@media screen and (max-width: 767px) {
    padding: 0 !important;
  }
  padding: 1.5rem 0px !important;
  margin-top: 0px !important;

.headerTitleMobile{
  h1{
    font-size:1.5em !important;
    text-align:center;
    text-transform: uppercase;
  }
  .headerTitleMobile1{
    font-weight: normal;
    color: ${props => props.theme.GREY};
  }
  .headerTitleMobile2{
    font-weight: bold;
    color: ${props => props.theme.BLUE};
  }
}
`;
