import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { menuQuery } from '../../App/atom';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

const MobileNav = (props) => {
    const { contents: menu } = useRecoilValueLoadable(menuQuery('menu_de'));
    const { visible = false, toggleMobileNav = () => { } } = props;
    const [activeItem, setActiveItem] = useState("/");
    const navigate = useNavigate();
    const navigateTo = (e, u) => {
        e.preventDefault();
        setActiveItem(u);
        toggleMobileNav();
        navigate(u);
    };
    return <S className={visible ? 'visible' : 'hidden'}>
        <div className="HeaderNavMenu">
            {(Array.isArray(menu) ? menu : []).map((item, idx) => {
                const { ID, title, url } = item;
                const t = url.replace('https://cms.koenitzer.info', '');
                const active = activeItem === t;
                return (
                    <Menu.Item
                        className={`menuItemLink ${active ? 'active' : ''}`} key={ID} link
                        href={t} name={t} active={active} onClick={(e) => navigateTo(e, t)}
                    >
                        <div
                            className={`menuItem ${menu.length - 1 === idx ? 'last' : ''}`}
                        >
                            <div className="menuItemText">
                                {title}
                            </div>
                        </div>
                    </Menu.Item>
                );
            })}
        </div>
    </S>;
}
export default MobileNav;
const S = styled.div`
@media screen and (min-width: 767px) {
    display:none;
    height:0;
  }
  &.visible{
      max-height:auto;
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: ease-in-out;
      opacity: 100;
      max-height:1000px;
  }
  &.hidden{
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: ease-in-out;
      opacity: 0;
      overflow:none;
      max-height:0px;
  }
  .HeaderNavMenu{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    a{
        text-decoration:none;
    }
    padding: 0 2rem 2rem 2rem;
    .menuItem {
        padding:0.5rem 0;
        width:100vw;
        text-align:center;
        border-bottom:1px solid ${props => props.theme.GREY};
    }
  }
`;