import React from 'react';
import styled from 'styled-components';

const BackgroundText = (props) => {
  const { text = '', position = 'l' } = props;
  const style = {
    right: position === 'r' ? '0px' : 'auto',
    left: position === 'r' ? 'auto' : '0px',
  };
  return <S style={style}>
    {text}
  </S>
};

export default BackgroundText;

const S = styled.div`
position: absolute;
  color: rgba(112, 111, 111, 0.1);
  font-size: 120px;
  letter-spacing: 5px;
  font-weight: bold;
  top:30%;
  left:0;
`;