import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Image } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { pageQuery, jobsQuery } from '../../App/atom';
import he from 'he';
import styled from 'styled-components';
import { get } from 'lodash';
import PageTitle from '../../Components/PageTitle';
import Slider from '../../Components/Slider';
import BackgroundText from '../../Components/BackgroundText';
import dummyImage from '../../transparent.png';

const Page = () => {
  const [imgUrl, setImgUrl] = useState(false);
  const [slides, setSlides] = useState([]);
  const { contents: pageData } = useRecoilValueLoadable(pageQuery("stellenangebote"));
  const { contents: jobsData } = useRecoilValueLoadable(jobsQuery());
  const plzGroups = useMemo(() => {
    let groups = {};
    if (Array.isArray(jobsData)) {
      jobsData.forEach(j => {
        const ao = get(j, 'acf.arbeitsplatzorte', []);
        ao.forEach(a => {
          const PLZPrefix = `plz-${get(a, 'postleitzahl', '-').substr(0, 1)}`;
          if (!groups[PLZPrefix]) {
            groups[PLZPrefix] = [];
          }
          groups[PLZPrefix].push(j)
        })
      });
    }
    return groups;
  }, [jobsData]);
  const plzGroupNames = Object.keys(plzGroups).filter(e => e !== 'plz--');

  const titleContent = get(pageData, 'acf.titel_content', false);
  const navigate = useNavigate();
  const navigateTo = (e, u) => {
    e.preventDefault();
    navigate(u);
  };
  useEffect(() => {
    if (pageData) {
      setImgUrl(get(pageData, 'acf.header_image', get(pageData, 'acf.bild_header')));
      setSlides(get(pageData, 'acf.header_slider', false));

    }
  }, [pageData]);
  console.log(imgUrl, slides.length);
  console.log(pageData)
  return <S>
    <Helmet
      title={get(pageData, 'acf.seo_title', get(pageData, 'title.rendered'))}
      meta={[
        { name: 'description', content: get(pageData, 'acf.seo_description', '') },
      ]}
    />
    {(imgUrl && (!slides || slides.length == 0)) && <div className="headerimg" style={{ background: `url(${imgUrl}) center top`, backgroundSize: 'cover' }}></div>}
    {(slides.length > 0) && <Slider slides={slides} />}
    <Container>
      <PageTitle title1={get(pageData, 'acf.titel_1', '')} title2={get(pageData, 'acf.titel_2', '')} />
      <Grid stackable className="contentBlocks" divided relaxed>
        <BackgroundText
          text={get(pageData, 'acf.hintergrundtext', '')}
        />
        <Grid.Row columns="3">
          <Grid.Column
            className="contentLeft" width="2" only="computer"
          />
          <Grid.Column className="contentLeft" computer="14" mobile="16">
            <div className="imageBlock">
              <Image
                src={get(pageData, 'acf.bild', get(pageData, 'acf.header_image', dummyImage))} fluid
              />
            </div>
            <div className="contentBlock">
              {titleContent ?
                <h2
                  dangerouslySetInnerHTML={{ __html: titleContent }}
                />
                : null}
              <div
                dangerouslySetInnerHTML={{ __html: get(pageData, 'acf.content', '') }}
              />
              <ul className="jobslist">
                {plzGroupNames.sort().map((p) => <li key={p}>
                  <em>PLZ-Gebiet {p.replace('plz-', '')}</em>
                  <ul>
                    {get(plzGroups, p).sort((a, b) => {
                      const nameA = get(a, 'slug', '').toUpperCase();
                      const nameB = get(b, 'slug', '').toUpperCase();
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    }).map((j) => <li key={get(j, 'slug', '')}><a href={`/jobs/${get(j, 'slug', '')}`} onClick={(e) => navigateTo(e, `/jobs/${get(j, 'slug', '')}`)}>{he.decode(get(j, 'title.rendered', ''))}</a></li>)}
                  </ul>
                </li>)}
              </ul>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

  </S>
}

export default Page;

const S = styled.div`
    a{
      text-decoration: none!important;
    }
                      .contentBlocks {
    @media screen and (max-width: 767px) {
                        margin - top: 0 !important;
    }
                      position:relative;
                      a {
                        text - decoration: none;
    }
                      h2 {
                        color:  ${props => props.theme.GREY} !important;
                      font-size: 1.3rem;
                      margin-bottom: 2.5rem !important;
                      @media screen and (max-width: 767px) {
                        margin - bottom: 0.5rem !important;
      }
    }
                      h3 {
                        color:  ${props => props.theme.GREY};
                      font-weight: normal;
                      text-decoration: none;
                      font-size: 1.1rem;
    }
                      ul{
                        padding - left:20px;
    }
  }
                      .googleMaps{

                      }
                      .imageBlock{
                        width:30%;
                      z-index:3;
                      float:left;
                      margin-top:-3rem;
                      @media screen and (max-width: 767px) {
                        position:absolute;
                      width:2.8rem;
                      display:inline-block;
                      margin-top:-2.5rem;
                      margin-left:-0.5rem;
                      img{
                        float:left !important;
      }
    }
  }
                      .contentLeft{
    @media screen and (max-width: 767px) {
                        border:none !important;
    }
                      margin-top:2rem;
  }
                      .contentBlock{
    @media screen and (max-width: 767px) {
                        margin - top:-2rem !important;
                      border:none !important;
    }
                      background:rgba(112, 111, 111,0.1);
                      padding:3rem 3rem 3rem 9rem;
                      width:70%;
                      z-index:2;
                      float:left;
                      margin-left:-6rem;
                      @media screen and (max-width: 767px) {
                        width:100%;
                      margin-left:0;
                      padding:3rem 1rem 1rem 1rem;
    }
  }
                      .headerimg{
                        height:65vh;
}
                      `;
