import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { menuQuery } from '../../App/atom';
import styled from 'styled-components';
import { Menu, Grid } from 'semantic-ui-react';

const FooterNav = () => {
  const { contents: menu } = useRecoilValueLoadable(menuQuery('menu_de_footer'));
  const navigate = useNavigate();
  const navigateTo = (e, u) => {
    e.preventDefault();
    navigate(u);
  };

  return <S>
    <div className="FooterNav">
      <div
        className="line"
      />
      <Grid doubling stackable>
        <Grid.Row columns={1}>
          <Grid.Column className="content" textAlign="center">
            <Menu text className="FooterNavMenu" compact>
              {(Array.isArray(menu) ? menu : []).map((item) => {
                const { ID, title, url } = item;
                const t = url.replace('https://cms.koenitzer.info', '');
                return <Menu.Item key={ID} name={t} onClick={(e) => navigateTo(e, t)}>
                  {title}
                </Menu.Item>
              })}
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </S>;
}
export default FooterNav;
const S = styled.div`
.FooterNav {
  margin-top: 2rem;
  position: relative;
  a {
    font-size: 9px;
  }
  .FooterNavMenu {
    margin: 0px !important;
    background: white !important;
    z-index: 1;
    a{
      line-height:1em;
      padding-top:0px !important;
      padding-bottom:0px !important;
      &:last-child{
        border:none;
      }
    }
  }
  .line {
    width: 100%;
    position: absolute;
    height: 0px;
    border-top: 1px dotted ${props => props.theme.GREY};
    z-index: 0;
    left: 0;
    top: 50%;
  }
  .nocache{
    position:absolute;
    z-index:99;
    bottom:0px;
    border: none !important;
    box-shadow: none !important;
    background:rgba(0,0,0,0) !important;
    color:rgba(20,20,20,0.1) !important;
    &:active, &:hover{
      border: none !important;
      box-shadow: none !important;
      background:rgba(0,0,0,0) !important;
    }
  }
}
`;