import React from 'react';
import { useRecoilValueLoadable } from "recoil";
import { Icon } from 'semantic-ui-react';
import { pageQuery } from '../../App/atom';
import styled from 'styled-components';
import { get } from 'lodash';

const SocialMediaLinks = (props) => {
  const { contents: pageData } = useRecoilValueLoadable(pageQuery('de'));
  let links = get(pageData, 'acf.social_media_links', []);
  return <S>
    <div
      className="line"
    />
    {Array.isArray(links) && <div className="SocialMediaLinksInner">
      {(links || []).map((l) => <a target="_blank" href={get(l, 'link')} key={get(l, 'link')} className="link">
        <Icon
          name={get(l, 'icon')}
        />
      </a>)}
    </div>}
  </S>
}

export default SocialMediaLinks;

const S = styled.div`
position: fixed;
  z-index: 1000;
  left: 0;
  top: 0px;
  padding-top: 100px;
  height: 65vh;
  width: 95.19px;
  background: white;
  .link {
    display: block;
    margin: 1rem auto;
    position: relative;
    text-align: center;
  }
  a {
    text-decoration: none !important;
    color: var(--GREY);
    &:hover {
      color: var(--BLUE);
    }
  }
  .SocialMediaLinksInner {
    background: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -9px;
    z-index: 2001;
  }
  .line {
    height: 100%;
    position: absolute;
    width: 0px;
    border-right: 1px dotted var(--GREY);
    z-index: 2000;
    left: 50%;
    top: 0;
  }
  @media screen and (max-width: 1023px) {
    height: 30vh;
  }
  @media screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    height: auto;
    padding: 0;
    text-align:center;
    .link {
      display: inline-block;
      float: left;
      margin:0.6rem 1rem 0.25rem 0rem;
      &:last-child{
        margin-right:0px !important;
      }
    }
    .SocialMediaLinksInner {
      padding: 0 1rem;
      left:0;
      background: white;
      position: relative;
      margin:0 auto !important;
      display:inline-block;
    }
    .line {
      left: 0;
      border-right: none !important;
      border-top: 1px dotted var(--GREY) !important;
      height: 1px;
      width: 100vw;
      position: relative;
    }
  }
`;
