import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { RecoilRoot, useRecoilState, useRecoilValueLoadable } from "recoil";
import HeaderLogo from '../Components/HeaderLogo';
import HeaderNav from '../Components/HeaderNav';
import FooterNav from '../Components/FooterNav';
import MobileNav from '../Components/MobileNav';
import SocialMediaLinks from '../Components/SocialMediaLinks';
import Home from '../Pages/Home';
import SubPage from '../Components/SubPage';
import SubPageJobs from '../Components/SubPageJobs';
import SubPageJobSingle from '../Components/SubPageJobSingle';
import { ThemeProvider } from "styled-components";
import request from 'superagent';
import '../lib/semantic.min.css';
import { globalState, scrollState, pageQuery } from './atom';
import { get } from 'lodash';
import { produce } from 'immer';

const theme = {
    GREY: 'rgb(112, 111, 111)',
    BLUE: 'rgb(62, 169, 224)'
};

const AppW = (props) => {
    return <RecoilRoot>
        <BrowserRouter>
            <App {...props} />
        </BrowserRouter>
    </RecoilRoot>;
}
const App = (props) => {
    const [global, setGlobal] = useRecoilState(globalState);
    const [menu, setMenu] = useState([]);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [menuFooter, setMenuFooter] = useState([]);
    const [scrollPos, setScrollPos] = useRecoilState(scrollState);
    const { contents: pageData } = useRecoilValueLoadable(pageQuery('de'));
    const navigate = useNavigate();
    const onScroll = () => {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
        const y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        setScrollPos(y);
    };
    useEffect(() => {
        getMenu('menu_de').then(d => setMenu(d));
        getMenu('menu_de_footer').then(d => setMenuFooter(d));
    }, []);
    useEffect(() => {
        setGlobal(produce(global, (draftState) => {
            draftState['menu'] = menu;
            draftState['menu_footer'] = menuFooter;
        }));
    }, [menu, menuFooter]);
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);

    return <React.Fragment>
        <ThemeProvider theme={theme}>
            <HeaderLogo toggleMobileNav={() => mobileMenu ? setMobileMenu(false) : setMobileMenu(true)} logourl={get(pageData, 'acf.logo')} mobilelogourl={get(pageData, 'acf.logo_mobil')} onClick={() => { navigate("/") }} />
            <SocialMediaLinks />
            <MobileNav visible={mobileMenu} toggleMobileNav={() => mobileMenu ? setMobileMenu(false) : setMobileMenu(true)} />
            <HeaderNav />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                    exact
                    path="/:lang/stellenangebote"
                    element={<SubPageJobs {...props} />}
                />
                <Route
                    exact
                    path="/jobs/:slug"
                    element={<SubPageJobSingle {...props} />}
                />
                <Route
                    exact
                    path="/:lang/:slug"
                    element={<SubPage {...props} />}
                />
                <Route path="*" element={<Home />} />
            </Routes>
            <FooterNav />
        </ThemeProvider>
    </React.Fragment >;
};
export default AppW;

export const getMenu = async (menu) =>
    new Promise((rs, rj) => {

        request
            .get(`${getCacheUrl()}/encoded_${encodeURIComponent(`https://cms.koenitzer.info/wp-json/wp-api-menus/v2/menu-locations/${menu}/`)}`)
            .then((response) => {
                rs(response.body || [].filter(el => el));
            })
            .catch((err) => { console.log(err); rj(err) });
    });
export const getPage = async (slug) =>
    new Promise((rs, rj) => {
        request
            .get(`${getCacheUrl()}/encoded_${encodeURIComponent(`https://cms.koenitzer.info/wp-json/wp/v2/pages?slug=${slug}/`)}`)
            .then((response) => {
                const d = (response.body || []).filter(el => el)
                if (d.length > 0) {
                    rs(d[0]);
                } else {
                    rs(null);
                }

            })
            .catch((err) => { console.log(err); rj(err) });
    });
export const getJob = async (slug) =>
    new Promise((rs, rj) => {
        request
            .get(`${getCacheUrl()}/encoded_${encodeURIComponent(`https://cms.koenitzer.info/wp-json/wp/v2/job?slug=${slug}/`)}`)
            .then((response) => {
                const d = (response.body || []).filter(el => el)
                if (d.length > 0) {
                    rs(d[0]);
                } else {
                    rs(null);
                }

            })
            .catch((err) => { console.log(err); rj(err) });
    });
export const getJobs = async (slug) =>
    new Promise((rs, rj) => {
        request
            .get(`${getCacheUrl()}/encoded_${encodeURIComponent(`https://cms.koenitzer.info/wp-json/wp/v2/job?per_page=100`)}`)
            .then((response) => {
                const d = (response.body || []).filter(el => el)
                if (d.length > 0) {
                    rs(d);
                } else {
                    rs([]);
                }

            })
            .catch((err) => { console.log(err); rj(err) });
    });
export const isproduction = () => {
    let p = true
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        p = false;
    }
    return p;
}
export const getCacheUrl = () => {
    return isproduction() ? 'https://cache.koenitzer.net' : 'https://develop.cache.ckcache.workers.dev'// 'https://dev.cache.b-coding.workers.dev'
};